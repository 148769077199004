import React from "react";
import BiraLogo from "../assets/bira_2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Logout } from "./Logout";

require("../styles/Base.scss");
//Includes the side bar and the top logout component
export const Base = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="base-container">
      <div>
        <div className="side-bar">
          <img alt=" " src={BiraLogo} className="side-bar-logo" />
          <div className="side-bar-options-box">
            <div className="side-bar-options">
              {/* each indvidual side bar options go here */}
              <div
                className="side-bar-row"
                style={{
                  opacity:
                    pathname === "/dashboard" || /details/.test(pathname)
                      ? 1
                      : 0.4,
                }}
                onClick={() => {
                  navigate("/dashboard");
                }}
              ></div>
            </div>
            <div
              style={{
                opacity: 0.8,
              }}
            >
              <Logout />
            </div>
          </div>
        </div>
      </div>
      <div className="right-half-container">
        <div className="children-component">{children}</div>
      </div>
    </div>
  );
};
