// return the user data from the session storage
export const setUserToken = (detail) => {
  sessionStorage.setItem("token", detail);
};
// return the token from the session storage
export const getUserToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const setUserLoginStatus = (detail) => {
  localStorage.setItem("loginStatus", detail);
};
// return the token from the session storage
export const getUserLoginStatus = () => {
  return localStorage.getItem("loginStatus") || null;
};

export const setAdminFlag = (detail) => {
  localStorage.setItem("isAdmin", detail);
};

// return the token from the session storage
export const getAdminFlag = () => {
  return localStorage.getItem("isAdmin") || null;
};

export const setTesterFlag = (detail) => {
  localStorage.setItem("isTester", detail);
};

export const getTesterFlag = () => {
  return localStorage.getItem("isTester") || null;
};

export const setTokenValidity = (detail) => {
  localStorage.setItem("tokenValidity", detail);
};
// return the token from the session storage
export const getTokenValidity = () => {
  return localStorage.getItem("tokenValidity") || null;
};

export const setOrgId = (orgId) => {
  localStorage.setItem("org_id", orgId);
};

export const setTillId = (tillId) => {
  localStorage.setItem("tillId", tillId);
};

export const setTillPassword = (tillPassword) => {
  localStorage.setItem("tillPassword", tillPassword);
};

export const getTillId = () => {
  return localStorage.getItem("tillId");
};

export const getTillPassword = () => {
  return localStorage.getItem("tillPassword");
};
