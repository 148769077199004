import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getTokenValidity, getUserLoginStatus } from "../auth";
import { Base } from "../components/Base";
import { UserDetails } from "../components/UserDetails/UserDetails";
import { UserDetailsWebApp } from "../components/WebApp/UserDetailsWebApp";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const UserDetailsPage = () => {
  //check if user is logged in
  const { coupon } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!getUserLoginStatus()) {
      navigate("/");
    }
    if (!getTokenValidity()) {
      toast.error("Token has expired ,please login again");
    }
  }, [navigate, coupon, dispatch]);

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Base>
        <UserDetails coupon={coupon} />
      </Base>
      <>
        <UserDetailsWebApp coupon={coupon} />
      </>
    </div>
  );
};
