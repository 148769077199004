import React, { useState } from "react";
import BIRA from "../../assets/bira_2.png";
import loginUser from "../../assets/loginUser.png";
import key from "../../assets/carbon_password.png";
import line from "../../assets/line.png";
import * as yup from "yup";
import { Formik } from "formik";
import { Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../slices/auth";

require("../../styles/Login.scss");
export const LoginWebApp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState();
  const validationSchema = yup.object({
    username: yup.string().min(8).required(),
    password: yup.string().min(5).required(),
  });
  return (
    <div className="login-webapp">
      <div className="login-webapp-row-1">
        <div className="login-webapp-header">
          <img className="login-webapp-logo" src={BIRA} alt="" />
        </div>
      </div>

      <div className="login-webapp-row-2">
        <div className="login-webapp-input-container">
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const tillId = values["username"];
              const tillPassword = values["password"];
              console.log("submit values", {
                tillId,
                tillPassword,
              });

              dispatch(userLogin({ tillId, tillPassword }))
                .unwrap()
                .then(() => {
                  navigate("../dashboard", { replace: true });
                })
                .catch((err) => {
                  console.log(err);
                  setErrorMsg("Please provide valid credentials and try again");
                });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <form onSubmit={handleSubmit}>
                <div className="">
                  <div className="input-container">
                    <img
                      alt=" "
                      className="input-container-logo"
                      src={loginUser}
                    />
                    <img
                      alt=" "
                      style={{ height: 30, marginTop: 15, opacity: 0.5 }}
                      src={line}
                    />
                    <Input
                      autoComplete={"off"}
                      className="login-input"
                      name="username"
                      value={values.username}
                      placeholder="Till ID"
                      onChange={handleChange}
                    />
                  </div>
                  <p className="display_error">
                    {errors.username ? "Till ID is a required field" : ""}
                  </p>

                  <div className="input-container">
                    <img alt=" " className="input-container-logo" src={key} />
                    <img
                      alt=" "
                      style={{ height: 30, marginTop: 15, opacity: 0.5 }}
                      src={line}
                    />
                    <Input.Password
                      className="login-input"
                      name="password"
                      value={values.password}
                      placeholder="Password"
                      visibilityToggle={false}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </div>
                  <p className="display_error">
                    {errors.password ? "Password is a required field" : ""}
                  </p>
                </div>
                <Button className="login-webapp-input-submit" htmlType="submit">
                  Login
                </Button>
                <div style={{ marginTop: 20, color: "red" }}>{errorMsg}</div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
