import { Row, Col, Typography } from "antd";
import QRCode from "react-qr-code";
import { redeemVoucher } from "../../services/redemption";
import { errorMsg, successMsg } from "../../utils/messagebox";
import React, { useState } from "react";

export const VoucherTab = (props) => {
  const { vouchers, coupon } = props;
  return (
    <div style={{ height: "80vh", overflowY: "scroll" }}>
      {vouchers.map((item, index) => {
        return <VoucherRow key={index} coupon={coupon} {...item} />;
      })}
    </div>
  );
};

export const VoucherRow = (props) => {
  const { setCouponDetails } = props;
  const { series_id, code, valid_till, customer } = props.coupon;
  const { Paragraph } = Typography;
  const [loading, setLoading] = useState(false);

  return (
    <Row style={{ marginBlock: "1rem" }}>
      <Col span={10} style={{ textAlign: "center" }}>
        <QRCode
          style={{ marginTop: "30px", marginLeft: "-30px" }}
          value={JSON.stringify(code)}
          size={145}
        />
      </Col>
      <Col span={14}>
        <div className="voucher-redeem-code">{`Coupon Code : ${code}`}</div>
        <div className="voucher-redeem-valid">{`Valid Till : ${valid_till}`}</div>
        <Paragraph
          className="voucher-redeem-series"
          ellipsis={{ tooltip: series_id }}
        >{`Series ID : ${series_id}`}</Paragraph>
        <div
          className="voucher-redeem-button"
          onClick={async () => {
            console.log(props.coupon, "coupon");
            console.log(customer, "RedemptionInfooo");
            const mobile = customer?.mobile;
            if (mobile && code) {
              setLoading(true);
              const result = await redeemVoucher({
                mobile,
                code: code,
              });

              console.log("result ==>", result);
              setLoading(false);
              if (result?.data?.status === "success") {
                setCouponDetails(null);
                successMsg("Coupon code redeem successfully!");
              } else {
                // errorMsg(
                //   "Active coupon code not issued to any registered customer"
                // );
                errorMsg(
                  "Given Coupon Code is not redeemable/has been previously redeemed!"
                );
              }
            } else {
              errorMsg(
                "Active coupon code not issued to any registered customer"
              );
            }
          }}
        >
          <div className="voucher-redeem-button-text">
            {/* <Spin spinning={loading} /> */}
            {loading ? "Redeeming" : "Redeem"}
          </div>
        </div>
      </Col>
    </Row>
  );
};
