import React from "react";
import {
  BrowserRouter as Routers,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Login } from "./components/Login";
import { ScanQrWebApp } from "./components/WebApp/ScanQrWebApp";
import { SearchUserPage } from "./pages/SearchUser";
import { TransactionsPage } from "./pages/Transactions";
import { UserDetailsPage } from "./pages/UserDetails";
import { getUserLoginStatus } from "./auth";
import { VouchersWebapp } from "./components/WebApp/VouchersWebapp";

const Router = () => {
  return (
    <Routers>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/dashboard" element={<SearchUserPage />} />
        <Route exact path="/details/:mobile" element={<UserDetailsPage />} />
        <Route exact path="/coupon/:coupon" element={<SearchUserPage />} />
        <Route exact path="/transactions" element={<TransactionsPage />} />
        <Route exact path="/scanqr" element={<ScanQrWebApp />} />
        <Route exact path="/vouchers/:mobile" element={<VouchersWebapp />} />
        <Route
          path="/*"
          element={
            getUserLoginStatus() ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </Routers>
  );
};
export default Router;
