import React, { useState } from "react";
import { SearchbarDropdown } from "./SearchBar";
require("../styles/SearchUsers.scss");

const defaultOptions = [];
for (let i = 0; i < 10; i++) {
  defaultOptions.push(`95679343434${i}`);
  defaultOptions.push(`9${i}6793434348`);
}

export const SearchUserComponent = () => {
  const [options, setOptions] = useState([]);
  const [visible, setVisible] = useState(false);
  const onInputChange = (event) => {
    if (
      event.target.value &&
      event.target.value !== "" &&
      event.target.value.length > 3
    ) {
      setVisible(true);
      setOptions(
        defaultOptions.filter((option) => option.includes(event.target.value))
      );
    } else {
      setVisible(false);
    }
  };

  return (
    <div className="search-user-container">
      <div>
        <div className="title">Dashboard</div>
      </div>

      <div className="search-bar-container">
        <SearchbarDropdown
          options={options}
          onInputChange={onInputChange}
          visible={visible}
        />
      </div>
    </div>
  );
};
