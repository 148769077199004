import { useEffect } from "react";
import { getTokenValidity, getUserLoginStatus } from "../auth";
import { Base } from "../components/Base";
import { SearchUserComponent } from "../components/SearchUser";
import { BaseWebApp } from "../components/WebApp/BaseWebApp";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

export const SearchUserPage = () => {
  const { coupon } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!getUserLoginStatus()) {
      navigate("/");
    }
    if (!getTokenValidity()) {
      toast.error("Token has expired ,please login again");
    }
  }, [navigate, coupon, dispatch]);

  //Base component is hidden if the screen size is less than 1000px, refer Base.scss
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Base>
        <SearchUserComponent />
      </Base>
      <BaseWebApp>
        <SearchUserComponent />
      </BaseWebApp>
    </div>
  );
};
