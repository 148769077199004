import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth";
import messageReducer from "../slices/message.js";
import userDetailsReducer from "../slices/users";
import voucherReducer from "../slices/vouchers";
const reducer = {
  auth: authReducer,
  message: messageReducer,
  user: userDetailsReducer,
  vouchers: voucherReducer,
};
const store = configureStore({
  reducer: reducer,
  devTools: true,
});
export default store;
