import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import { Navigate, useNavigate } from "react-router-dom";

require("../../styles/webapp/QrScanner.scss");
export const ScanQrWebApp = () => {
  const [data, setData] = useState("No result");
  const navigate = useNavigate();
  const [navigateFlag, setNavigateFlag] = useState(false);

  return (
    <>
      <div style={{ paddingLeft: "5vw", paddingTop: 30 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            marginBottom: 20,
          }}
        >
          <ArrowLeftOutlined
            style={{ color: "black", fontSize: "1.5em" }}
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 18,
            color: "#000000",
            marginBottom: 6,
          }}
        >
          Scan QR Code
        </div>
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: 14,
            color: "#000000",
            opacity: 0.4,
          }}
        ></div>
        {/* Lorem ipsum dolor sit amet, consectetur adipig elittellus egestas in
        sapien. */}
      </div>

      <div style={{}}>
        <QrReader
          delay={500}
          autoFocus={true}
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            console.log("result==>", result);
            if (!!result) {
              setNavigateFlag(true);
              localStorage.setItem("qr_code", result.text);
              setData(result?.text);
              // onSearch(result?.text);
            }
          }}
          videoStyle={{ width: "90%", marginLeft: "5vw", marginTop: "5%" }}
          videoId="scanner"
        />
        {data && navigateFlag && <Navigate to={`/dashboard`} />}
        {/* {couponDetails ? (
          <Modal
            className="Modal-Redeem"
            open={true}
            footer={null}
            onCancel={() => {
              setCouponDetails(null);
            }}
            onOk={() => {
              setCouponDetails(null);
            }}
            centered
            width={"600px"}
            bodyStyle={{ height: 300 }}
          >
            <>
              <VoucherRow
                coupon={couponDetails}
                setCouponDetails={() => setCouponDetails(null)}
              />
            </>
          </Modal>
        ) : null} */}
      </div>
    </>
  );
};
