import axios from 'axios';
import { useState, useEffect } from 'react';
import { Button, Col, Input, Row, Typography } from 'antd';
import { apiUrl } from '../Url';
import { getUserToken } from '../auth';
import { successMsg, errorMsg } from '../utils/messagebox';

const VoucherIssue = (props) => {
  const { mobile } = props;
  const { Title } = Typography;
  const [isLoading, setLoading] = useState(true);
  const [seriesCode, setSeriesCode] = useState('');
  useEffect(() => {
    if (seriesCode) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [seriesCode]);
  const handleClick = () => {
    setLoading(true);
    const data = {
      mobile: mobile,
      series_id: seriesCode,
    };
    const config = {
      method: 'post',
      url: `${apiUrl}/redemptions/issue-series`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    };
    axios(config)
      .then((res) =>
        successMsg(
          res?.response?.coupon?.item_status?.message ??
            'Series issued successfully',
        ),
      )
      .catch((error) =>
        errorMsg(error?.response?.data?.message ?? 'Series not issued'),
      )
      .finally(() => setSeriesCode(''));
  };
  return (
    <div className="voucher-issue-webapp-col-3">
      <Row justify="center" style={{ marginTop: '2rem' }}>
        <Col span={24}>
          <Title level={5}>Series Coupon</Title>
        </Col>
      </Row>
      <Row justify="center" gutter={16}>
        <Col span={18}>
          <Input
            value={seriesCode}
            style={{
              borderRadius: 10,
              border: '1px solid #f0f0f0',
              paddingLeft: 20,
              fontSize: 15,
              fontFamily: 'Poppins',
            }}
            placeholder="Enter series code"
            onChange={(e) => setSeriesCode(e.target.value)}
          />
        </Col>
        <Col span={6}>
          <Button
            disabled={isLoading}
            className="voucher-redeem-button voucher-redeem-button-text"
            style={{ marginTop: 0 }}
            onClick={handleClick}
          >
            Issue
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default VoucherIssue;
