import React, { useEffect, useState } from "react";
import loginBackGroundImage from "../assets/Bira_bottle.png";
import loginUser from "../assets/loginUser.png";
import key from "../assets/carbon_password.png";
import line from "../assets/line.png";
import * as yup from "yup";
import { Formik } from "formik";
import { Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../slices/auth";
import { LoginWebApp } from "./WebApp/LoginWebApp";
import { getUserLoginStatus } from "../auth";

require("../styles/Login.scss");

export const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState();
  const validationSchema = yup.object({
    username: yup.string().min(8).required(),
    password: yup.string().min(5).required(),
  });
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    if (getUserLoginStatus()) {
      navigate("../dashboard", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  return (
    <div>
      <div className="login-column-container">
        <div className="login-column-1">
          <img alt=" " src={loginBackGroundImage} />
        </div>
        <div className="login-column-2">
          <div className="login-component-container">
            <div className="logo-container">WELCOME TO BIRA!</div>
            <Formik
              initialValues={{
                username: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const tillId = values["username"];
                const tillPassword = values["password"];

                dispatch(userLogin({ tillId, tillPassword }))
                  .unwrap()
                  .then(() => {
                    navigate("../dashboard", { replace: true });
                  })
                  .catch((err) => {
                    console.log(err);
                    setErrorMsg(
                      "Please provide valid credentials and try again"
                    );
                  });
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="login-box-container">
                      <div className="input-container">
                        <img
                          alt=" "
                          className="input-container-logo"
                          src={loginUser}
                        />
                        <img
                          alt=" "
                          style={{
                            height: 30,
                            marginTop: 15,
                            opacity: 0.5,
                          }}
                          src={line}
                        />
                        <Input
                          className="login-input"
                          name="username"
                          value={values.username}
                          placeholder="Till ID"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <p className="display_error">
                        {errors.username ? "Till ID is a required field" : ""}
                      </p>

                      <div className="input-container">
                        <img
                          alt=" "
                          className="input-container-logo"
                          src={key}
                        />
                        <img
                          alt=" "
                          style={{
                            height: 30,
                            marginTop: 15,
                            opacity: 0.5,
                          }}
                          src={line}
                        />
                        <Input.Password
                          className="login-input"
                          name="password"
                          value={values.password}
                          placeholder="Password"
                          visibilityToggle={false}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <p className="display_error">
                        {errors.password ? "Password is a required field" : ""}
                      </p>
                    </div>
                    <Button className="input-submit" htmlType="submit">
                      Login
                    </Button>
                    <div style={{ marginTop: 20, color: "red" }}>
                      {errorMsg}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <LoginWebApp />
    </div>
  );
};
