import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserDetails } from '../services/User';
const initialState = {};

export const retrieveUserDetail = createAsyncThunk(
  'user/details',
  async (mobile) => {
    const res = await getUserDetails(mobile);
    return res.data;
  },
);

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  extraReducers: {
    [retrieveUserDetail.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

const { reducer } = userDetailsSlice;
export default reducer;
