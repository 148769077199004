import React, { useEffect, useState } from 'react';
import { Row, Table } from 'antd';
import { fetchAllTransactions } from '../../services/redemption';
require('../../styles/Transactions.scss');
export const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(4);

  useEffect(() => {
    fetchAllTransactions().then((result) => {
      setTransactions(result);
    });
  }, []);
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customername',
    },
    {
      title: 'Voucher ID',
      dataIndex: 'voucherId',
      key: 'voucherId',
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
    },
    {
      title: 'Time stamp',
      dataIndex: 'timeStamp',
      key: 'timeStamp',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <div className="transactions">
      <div className="transaction-title">Transactions</div>
      {/* <div
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 16,
          height: 50,
          color: "#000000",
          marginLeft: 50,
          contain: "content",
          opacity: 0.4,
          width: "80vw",
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipig elittellus egestas in
        sapien.
      </div> */}
      <Row className="transactions-container">
        <Table
          dataSource={transactions}
          onChange={(page) => {
            if (page && page.current && page.pageSize) {
              setPageNumber(page.current);
              setPageSize(page.pageSize);
            }
          }}
          columns={columns}
          bordered
          pagination={{
            defaultPageSize: 4,
            defaultCurrent: 1,
            style: {
              marginTop: 10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              width: 400,
            },
          }}
        />
        {transactions.length && (
          <div className="transaction-pagination">
            Showing {pageNumber * pageSize - pageSize + 1} to{' '}
            {pageNumber * pageSize < transactions.length
              ? pageNumber * pageSize
              : transactions.length}{' '}
            of {transactions.length} entries
          </div>
        )}
      </Row>
    </div>
  );
};
