import axios from "axios";
import { getTillId, getTillPassword, setTokenValidity } from "../auth";
import { apiUrl } from "../Url";
import ApiHelper from "../utils/ApiHelper";

export const redeemVoucher = async ({ mobile, code }) => {
  let data = JSON.stringify({
    couponCode: code,
    mobile: `${mobile}`,
    tillId: getTillId(),
    tillPassword: getTillPassword(),
  });
  const result = await ApiHelper.post("/coupon/redeem", data);
  return result;
};

export const fetchAllTransactions = async () => {
  let config = {
    method: "get",
    url: `${apiUrl}/coupon`,
    headers: {
      "app-key": process.env.REACT_APP_BIRA_APPKEY,
      "app-secret": process.env.REACT_APP_BIRA_APP_SECRET,
    },
  };

  let allTransactions = [];
  const result = await axios(config).catch((error) => {
    console.log("redeem error", error);
    setTokenValidity(false);
    return error;
  });
  console.log("result", result);
  const { data } = result;

  if (data && data.data) {
    data.data.forEach((item) => {
      if (
        item.user &&
        item.redemption_request &&
        item.redemption_response &&
        item.redemption_response.redemption
      ) {
        const { redemption } = item.redemption_response;
        const { user, redemption_request, redemption_response } = item;
        const { redemptionStatus } = redemption_response;
        const { status } = redemptionStatus;

        redemption.forEach((transaction) => {
          allTransactions.push({
            customerName: user.firstname + " " + user.lastname,
            voucherId: transaction.id,
            transactionId: redemption_request.transactionNumber,
            timeStamp: item.createdAt,
            status: status ? "Redeemed" : "Not Redeemed",
          });
        });
      }
    });
  }

  const transactions = allTransactions.map((item, index) => {
    return { serialNumber: index + 1, ...item };
  });
  return transactions;
};
