import React from "react";
import { VouchersBox } from "./VouchersBox";
require("../../styles/UserDetails.scss");

export const UserDetails = (props) => {
  const { coupon } = props;

  console.log("coupon ===>", coupon);
  return (
    <div className="details-container">
      <div className="details-body">
        <VouchersBox coupon={coupon} />
      </div>
    </div>
  );
};
