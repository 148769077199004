import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    console.log("interceptor request config", config);
    if (config?.headers) {
      config.headers["app-secret"] = process.env.REACT_APP_BIRA_APP_SECRET;
      config.headers["app-key"] = process.env.REACT_APP_BIRA_APPKEY;
      config.headers["Content-Type"] = "application/json";
    }
    return { ...config };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    console.log("interceptor response");
    return response;
  },
  function (error) {
    return error;
  }
);
class ApiHelper {
  get(url, params = {}) {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}${url}`;
    return axios.get(apiUrl, params, {});
  }

  post(url, body = {}) {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}${url}`;
    return axios.post(apiUrl, body, {});
  }
}
const ApiHelperInstance = new ApiHelper();
export default ApiHelperInstance;
