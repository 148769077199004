import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveUserDetail } from '../../slices/users';
import { Spin } from 'antd';

require('../../styles/DetailsBox.scss');
export const DetailsBox = (props) => {
  const { mobile } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => {
    return state.user;
  });

  useEffect(() => {
    dispatch(retrieveUserDetail(mobile));
    setLoading(false);
  }, [mobile, dispatch]);

  return (
    <div className="user-details-box">
      <div className="user-details-title">User Details</div>

      {loading && (
        <div className="user-details-webapp-qrcode">
          <Spin tip="loading.." />
        </div>
      )}
      {!loading && (
        <>
          <div className="user-details-webapp-qrcode">
            <QRCode value={`${mobile}`} size={80} />
          </div>
          <div className="user-details-content">
            <div className="user-details-content-row">
              <div className="user-detail-row-header">Full Name:</div>
              <div className="user-detail-row-values">
                {user.data && user.data.firstname
                  ? user.data.firstname + ' ' + user.data.lastname
                  : ' '}
              </div>
            </div>

            <div className="user-details-content-row">
              <div className="user-detail-row-header">Phone Number:</div>
              <div className="user-detail-row-values">
                {user.data && user.data.mobile ? user.data.mobile : ' '}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
