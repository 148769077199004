import React, { useState } from 'react';
import backArrow from '../../assets/backArrow.png';
import { Navigate, useNavigate } from 'react-router-dom';
import { DetailsBox } from '../UserDetails/DetailsBox';
import VoucherIssue from '../VoucherIssue';
import { getAdminFlag, getTesterFlag } from '../../auth';
require('../../styles/webapp/_UserDetailsWebApp.scss');
export const UserDetailsWebApp = (props) => {
  const navigate = useNavigate();
  const [showVouchersFlag, setShowVouchersFlag] = useState(false);
  const { mobile } = props;
  const isAdmin = getAdminFlag() === 'true' ? true : false;
  const isTester = getTesterFlag() === 'true' ? true : false;

  return (
    <div className="user-details-webapp">
      {!showVouchersFlag && (
        <div className="user-details-webapp-row-1">
          <div className="back-row">
            <img
              className="back-arrow"
              src={backArrow}
              alt=""
              onClick={() => {
                navigate('/dashboard');
              }}
            />
            <div className="back-text">Back</div>
          </div>
          <div className="user-details-webapp-header">
            <div className="">User Details</div>
            <div className="quote">
              {/* Lorem ipsum dolor sit amet, consectetur adipig elittellus egestas
              in sapien. */}
            </div>
          </div>
        </div>
      )}

      {showVouchersFlag && <Navigate to={`/vouchers/${mobile}`} />}
      {!showVouchersFlag && (
        <>
          <div className="user-details-webapp-row-2">
            <DetailsBox mobile={mobile} />
          </div>
          <div className="user-details-webapp-row-3">
            <div
              className="view-vouchers-box"
              onClick={() => {
                setShowVouchersFlag(true);
              }}
            >
              View All Available Vouchers
            </div>
          </div>
        </>
      )}
      {(isAdmin || isTester) && <VoucherIssue mobile={mobile} />}
    </div>
  );
};
