import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserCoupons } from "../services/User";
const initialState = [];

export const retrieveUserVouchers = createAsyncThunk(
  "user/vouchers",
  async (mobile, couponCode) => {
    const result = await getUserCoupons(mobile, couponCode);
    return result;
  }
);

const voucherSlice = createSlice({
  name: "vouchers",
  initialState,
  extraReducers: {
    [retrieveUserVouchers.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

const { reducer } = voucherSlice;
export default reducer;
