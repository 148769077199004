import { getUserLoginStatus, setTokenValidity } from "../auth";
import ApiHelper from "../utils/ApiHelper";

export const logout = async () => {
  sessionStorage.removeItem("token");
  localStorage.removeItem("loginStatus");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("tokenValidity");
  localStorage.removeItem("tillId");
  localStorage.removeItem("tillPassword");
  console.log(getUserLoginStatus());
};

export const login = async (data) => {
  console.log(data);
  const result = await ApiHelper.post(`/orgs/login`, data);
  console.log(result);
  setTokenValidity(true);
  return result.data;
};
