import axios from "axios";
import { getUserToken, setTokenValidity } from "../auth";
import { apiUrl } from "../Url";
import ApiHelper from "../utils/ApiHelper";

export const getCouponDetails = async (code) => {
  const result = await ApiHelper.get(`/coupons/${code}`).catch((error) => {
    return error;
  });

  return result.data;
};

export const getUserCoupons = async (mobile) => {
  let config = {
    method: "get",
    url: `${apiUrl}/users/coupons?mobile=` + mobile,
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    },
  };
  const result = await axios(config).catch((error) => {
    setTokenValidity(false);
    return error;
  });

  return result.data;
};

export const getUserDetails = async (mobile) => {
  let config = {
    method: "get",
    url: `${apiUrl}/users/details?mobile=` + mobile,
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    },
  };
  const result = await axios(config).catch((error) => {
    setTokenValidity(false);
    return error;
  });

  return result;
};
