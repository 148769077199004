import { Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCoupons } from '../../services/User';
import { useNavigate, useParams } from 'react-router-dom';
import { retrieveUserDetail } from '../../slices/users';
import { VoucherTab } from '../UserDetails/VoucherRow';
import { CloseOutlined, RedoOutlined } from '@ant-design/icons';

export const VouchersWebapp = (props) => {
  const { mobile } = useParams();
  const navigate = useNavigate();

  const hasWindow = typeof window !== 'undefined';
  const width = hasWindow ? window.innerWidth : 800;

  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state.user;
  });

  const [userCoupons, setUserCoupons] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserCoupons(mobile).then((result) => {
      if (result && result.token) {
        setUserCoupons(result.token);
      }
    });
    dispatch(retrieveUserDetail(mobile));
    setLoading(false);
  }, [mobile, dispatch, navigate, width, loading]);

  useEffect(() => {
    if (userCoupons.length > 0) {
      const results = userCoupons.filter((item) =>
        item.code.toLowerCase().includes(nameSearch.toLowerCase()),
      );
      setFilterData(results);
    }
  }, [nameSearch, userCoupons]);

  return (
    <div>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingRight: '10vw',
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 30,
            fontFamily: 'Poppins',
            fontSize: 25,
            /* identical to box height */
            color: '#000000',
          }}
        >
          Active Vouchers
        </div>

        <div
          style={{
            width: 80,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <RedoOutlined
            rotate={-60}
            style={{
              marginTop: 27,
              color: 'dodgerblue',
              fontSize: '1.5em',
            }}
            onClick={() => {
              setLoading(true);
              setNameSearch('');
            }}
          />

          <CloseOutlined
            style={{
              marginTop: 27,
              color: 'red',
              fontSize: '1.5em',
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
      </Row>

      <div style={{ height: '85vh' }}>
        {loading && (
          <div style={{ fontSize: 18, marginLeft: 30, margintop: 50 }}>
            Fetching All Vouchers ...
          </div>
        )}
        {!loading && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingLeft: 30,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <div
                style={{
                  border: '2px solid #F0F0F0',
                  borderRadius: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '1rem',
                  height: 100,
                  width: 100,
                }}
              >
                <QRCode value={JSON.stringify(mobile)} size={70} />
              </div>

              <Col>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div> Full Name: </div>
                  <div>
                    {' '}
                    {user.data && user.data.firstname
                      ? user.data.firstname + ' ' + user.data.lastname
                      : ' '}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div> Phone No: </div>
                  <div> {mobile}</div>
                </div>
              </Col>
            </div>
            <div style={{ paddingBottom: '1rem' }}>
              <Input
                value={nameSearch}
                style={{
                  width: '80vw',
                  margintop: 50,
                  borderRadius: 10,
                  height: 50,
                  paddingLeft: 20,
                  fontSize: 15,
                  border: '1px solid gray',
                  fontFamily: 'Poppins',
                  marginLeft: 30,
                }}
                placeholder={'Search coupon code'}
                onChange={(event) => {
                  setNameSearch(event.target.value);
                }}
              />
            </div>
            {!filterData.length ? (
              <div style={{ marginLeft: '30vw', marginTop: 200, fontSize: 25 }}>
                Loading...
              </div>
            ) : (
              <VoucherTab mobile={mobile} vouchers={filterData} />
            )}
          </>
        )}
      </div>
    </div>
  );
};
