import React from "react";
import { useNavigate } from "react-router-dom";
import BIRA from "../../assets/bira_2.png";
import logOut from "../../assets/logout.png";
import { useDispatch } from "react-redux";
import { userLogout } from "../../slices/auth";
require("../../styles/Base.scss");
export const BaseWebApp = ({ val, children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(userLogout())
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="base-webapp">
      <div className="base-webapp-row-1">
        <div className="base-webapp-header">
          <img className="base-webapp-logo" src={BIRA} alt="" />
        </div>
      </div>
      <div className="base-webapp-row-2">{children}</div>
      <div className="base-webapp-row-3">
        <div
          style={{
            marginTop: "5%",
            height: 50,
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            backgroundColor: "black",
          }}
        >
          <div className="base-webapp-footer-row">
            <img src={logOut} alt="" />
            <div
              className="base-webapp-footer-text"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
