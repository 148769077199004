import React from "react";
import { useDispatch } from "react-redux";
import { userLogout } from "../slices/auth";
import logoutImage from "../assets/logout.png";
import arrow from "../assets/arrow.png";
import { useNavigate } from "react-router-dom";
require("../styles/Base.scss");

export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch(userLogout())
      .unwrap()
      .then(() => {
        navigate("../", { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className="side-bar-logout"
      onClick={() => {
        logout();
      }}
    >
      <img alt=" " src={logoutImage} className="side-bar-row-icon" />
      <div className="side-bar-logout-text">Logout </div>
      <img alt=" " src={arrow} className="side-bar-row-arrow" />
    </div>
  );
};
