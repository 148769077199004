import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import searchIcon from "../assets/Search.png";
import scanQr from "../assets/scanQr.png";
import { useNavigate } from "react-router-dom";
import { Button, Row, Spin, Modal } from "antd";
import { getCouponDetails } from "../services/User";
import { ScanOutlined, SearchOutlined } from "@ant-design/icons";
import { VoucherRow } from "./UserDetails/VoucherRow";
import { errorMsg } from "../utils/messagebox";
require("../styles/SearchBar.scss");

export const SearchbarDropdown = (props) => {
  const { onInputChange } = props;
  const navigate = useNavigate();
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [couponDetails, setCouponDetails] = useState(null);
  const [mount, setMount] = useState(false);
  const [showRedeemptionModal, setShowRedeemptionModal] = useState(false);

  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem("qr_code");
    };
  }, []);

  useEffect(() => {
    setMount(true);
  }, []);

  useEffect(() => {
    if (mount) {
      const qrCode = localStorage.getItem("qr_code");
      if (qrCode) {
        onSearch(qrCode);
        localStorage.removeItem("qr_code");
      }
    }
  }, [mount]);

  const onSearch = (inputText) => {
    setLoading(true);
    getCouponDetails(inputText.trim()).then((result) => {
      setLoading(false);
      if (result?.status === "success" && result?.result?.coupon?.length > 0) {
        setShowRedeemptionModal(true);
        setCouponDetails(result?.result?.coupon[0]);
        setInputText("");
      } else {
        errorMsg("No user found having this Coupon code");
        setShowRedeemptionModal(false);
      }
    });
  };

  return (
    <div>
      <div className="search-bar-row-container">
        <div className="search-bar-dropdown">
          <div className="search-icon">
            <img alt=" " src={searchIcon} />
          </div>
          <input
            id="search-bar"
            type="text"
            className="form-control"
            placeholder="Search for Coupon code"
            autoComplete="off"
            value={inputText}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.code === "NumpadEnter") {
                setLoading(true);
                if (inputText) {
                  onSearch(inputText);
                }
                setLoading(false);
              }
            }}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
          />
          <div
            className="scan-qr"
            onClick={() => {
              navigate("/scanqr");
            }}
          >
            <img alt=" " src={scanQr} />
          </div>
        </div>

        <div
          className="search-button"
          style={{ opacity: !inputText ? 0.5 : 1 }}
          onClick={(e) => {
            //call search member by mobile to validate if user exists for the provided input
            if (inputText) {
              onSearch(inputText);
            }
          }}
        >
          Search
        </div>
      </div>
      {/* {invalidSearchFlag && (
        <div className="Validation-text">
          {" "}
          No user found having this Coupon code
        </div>
      )} */}
      <Row
        className="search-bar-webapp-row"
        style={{
          width: "100vw",
          flexDirection: "row",
          justifyContent: "flex-start",
          paddingLeft: 50,
          height: 50,
        }}
      >
        <Row
          style={{
            flexDirection: "row",
            background: "#FFFFFF",
            border: "1px solid #F0F0F0",
            borderRadius: 10,
            height: 50,

            contain: "content",
          }}
        >
          <SearchOutlined
            style={{ paddingBlock: "1rem", paddingLeft: "1rem" }}
          />

          <input
            id="search-bar"
            type="text"
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              background: "white",
            }}
            placeholder="Scan coupon code"
            autoComplete="off"
            onChange={(e) => {
              setInputText(e.target.value);
              onInputChange(e);
            }}
          />
          <ScanOutlined
            style={{ paddingBlock: "1rem", paddingRight: "1rem" }}
            onClick={() => {
              navigate("/scanqr");
            }}
          />
        </Row>
      </Row>
      <Row
        className="search-bar-webapp-row"
        style={{
          width: "100vw",
          flexDirection: "row",
          justifyContent: "center",
          height: 50,
          marginTop: 20,
        }}
      >
        <Button
          className="search-button-mobile"
          disabled={!inputText && true}
          onClick={(e) => {
            //call search member by mobile to validate if user exists for the provided input
            if (inputText) {
              onSearch(inputText);
            }
          }}
        >
          <div style={{ fontSize: 18, marginTop: -3 }}>Search</div>
        </Button>
      </Row>
      {couponDetails && showRedeemptionModal ? (
        <Modal
          className="Modal-Redeem"
          open={true}
          footer={null}
          onCancel={() => {
            localStorage.removeItem("qr_code");
            setCouponDetails(null);
          }}
          onOk={() => {
            localStorage.removeItem("qr_code");
            setCouponDetails(null);
          }}
          centered
          width={"600px"}
          bodyStyle={{ height: 300 }}
        >
          <>
            <VoucherRow
              coupon={couponDetails}
              setCouponDetails={() => {
                localStorage.removeItem("qr_code");
                setCouponDetails(null);
              }}
            />
          </>
        </Modal>
      ) : null}
      {loading && (
        <Modal
          width={1}
          centered
          open={loading}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "transparent" }}
          mask={false}
          maskClosable={false}
          style={{
            backgroundColor: "transparent",
            alignItems: "center",
            justifyContent: "center",
          }}
          bodyStyle={{ padding: 0, backgroundColor: "transparent" }}
        >
          <div className="spinner-wrapper">
            <Spin size="large" spinStyle={{ color: "black" }} />
          </div>
        </Modal>
      )}
    </div>
  );
};
