import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokenValidity, getUserLoginStatus } from '../auth';
import { Base } from '../components/Base';
import { Transactions } from '../components/Transactions/Transactions';
import { BaseWebApp } from '../components/WebApp/BaseWebApp';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const TransactionsPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!getUserLoginStatus()) {
      navigate('/');
    }
    if (!getTokenValidity()) {
      toast.error('Token has expired ,please login again');
    }
  }, [navigate]);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Base>
        <Transactions />
      </Base>
      <BaseWebApp>
        <Transactions />
      </BaseWebApp>
    </div>
  );
};
