import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { VoucherRow } from "./VoucherRow";
import { getCouponDetails } from "../../services/User";
require("../../styles/VouchersBox.scss");

export const VouchersBox = (props) => {
  const { coupon } = props;
  const [loading, setLoading] = useState(false);
  const [couponDetails, setCouponDetails] = useState(null);

  const getCoupon = async (coupon) => {
    setLoading(true);
    const result = await getCouponDetails(coupon).catch((err) => {
      setLoading(false);
    });
    setLoading(false);
    if (result?.status === "success" && result?.result?.coupon?.length > 0) {
      setCouponDetails(result?.result?.coupon[0]);
    }
    console.log("gtet result ==", result);
  };

  useEffect(() => {
    getCoupon(coupon);
  }, [coupon]);

  return (
    <div className="available-vocuhers-box">
      <div className="sticky-div"></div>
      {loading && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <Spin size="large" tip="loading ..." />
        </div>
      )}
      {couponDetails ? (
        <VoucherRow coupon={couponDetails} />
      ) : (
        <p>No coupon found</p>
      )}
    </div>
  );
};
